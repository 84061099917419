@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Gwendolyn:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  background: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: grid;
  height: 100vh;
  grid-template-rows: 80px 3fr 1fr;
}

.wave {
  clip-path: polygon(
    22% 62%,
    24% 46%,
    36% 38%,
    39% 25%,
    49% 15%,
    54% 0,
    100% 0,
    100% 100%,
    28% 100%,
    0 100%,
    9% 88%,
    11% 74%
  );
}

.title {
  font-family: 'Roboto', sans-serif;
}

.subtitle {
  font-family: 'Gwendolyn', cursive;
}

.ourservice_grid {
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.rotate {
  display: inline-block;
  transform: rotateZ(-10deg);
}

.logement__bg {
  clip-path: polygon(
    65% 100%,
    0% 99.8%,
    0% 0%,
    28.2% 0%,
    100% 0%,
    99.6% 15.6%,
    99.6% 29.8%,
    99.8% 39.4%,
    99.8% 54.8%,
    75.6% 58.4%,
    84.8% 67.4%,
    76% 86.6%
  );
}

.reception {
  clip-path: polygon(
    100% 100%,
    0% 99.6%,
    0% 0%,
    14.6% 8.2%,
    32.8% 31.2%,
    69% 36.4%,
    92.4% 43.2%,
    99.8% 50.2%,
    100% 66%,
    67.8% 73%,
    73.8% 83.8%,
    74.6% 98.2%
  );
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 400px);
}

.integration {
  clip-path: polygon(
    44.2% 60.8%,
    57.8% 70.4%,
    68.2% 75.8%,
    76.2% 83.6%,
    87% 93.2%,
    83.4% 99.6%,
    0% 99.6%,
    0% 0%,
    6.6% 12.6%,
    9% 21.6%,
    26.6% 33.2%,
    30.2% 45%,
    39.6% 52.4%
  );
}

.accompagnement__bg {
  clip-path: polygon(
    18.8% 18.2%,
    12.4% 30.8%,
    9.6% 47.8%,
    5% 57%,
    4.6% 80%,
    0.4% 93.4%,
    0% 99.6%,
    0% 0%,
    44.2% 0%,
    37.4% 4.2%,
    36.8% 10.2%,
    31.2% 13%,
    26.4% 17.2%
  );
}

.job {
  clip-path: polygon(
    66.8% 36.8%,
    46.6% 40.8%,
    30.4% 36.4%,
    14.2% 38.6%,
    7.4% 22.8%,
    0% 20.2%,
    0% 8.4%,
    0% 0%,
    99.6% 0%,
    99.4% 28.2%,
    93.4% 22%,
    87.4% 28.8%,
    77% 34.2%
  );
}

.contact {
  clip-path: polygon(
    100% 100%,
    0% 98.8%,
    0.6% 77.8%,
    18.6% 78%,
    35.6% 75.2%,
    51.4% 76.2%,
    67.2% 73.6%,
    81% 68%,
    88.6% 65.8%,
    93.2% 60.6%,
    94.8% 64.4%,
    100% 63.2%,
    100% 91.2%
  );
}

.personal__grid {
  grid-template-rows: repeat(2, 480px);
}

.teams {
  clip-path: polygon(
    65% 100%,
    0% 99.8%,
    0% 0%,
    28.2% 0%,
    42.8% 13.2%,
    57.4% 23.2%,
    48% 32%,
    56.2% 35.8%,
    78.4% 48.8%,
    75.6% 58.4%,
    84.8% 67.4%,
    76% 86.6%
  );
}

.navbtn {
  cursor: pointer;
  z-index: 9999;
}

.installion {
  /* clip-path: polygon(
    80% 42%,
    100% 1%,
    100% 63%,
    100% 100%,
    0 100%,
    58% 78%,
    10% 50%
  ); */
}

.list-items {
  list-style: none;
  counter-reset: list-counter;
  font-size: 20px;
}

.list-items li {
  counter-increment: list-counter;
  position: relative;
}

.list-items li::before {
  content: counter(list-counter);
  position: absolute;
  left: -28px;
  top: -5;
  background-color: #f9920b;
  border: 1px solid #000; /* Example border */
  color: #fff; /* Text color */
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  border-radius: 50%; /* Example to make it circular */
  font-weight: bold;
  font-variant-numeric: tabular-nums;
}

@media (min-width: 768px) {
  .list-items li {
    padding-left: 2em;
  }

  .list-items li::before {
    left: 0;
    top: 0;
  }
  .ourservice_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .logement__bg {
    clip-path: polygon(
      0 100%,
      0 0,
      55% 0,
      51% 10%,
      42% 18%,
      43% 27%,
      37% 35%,
      37% 47%,
      29% 56%,
      28% 71%,
      16% 76%,
      13% 87%
    );
  }
  .reception {
    clip-path: polygon(
      49.8% 58.4%,
      63.4% 64.8%,
      68.2% 75.8%,
      80.2% 79.6%,
      87% 93.2%,
      98.6% 100%,
      0% 99.6%,
      0% 0%,
      12.6% 9.2%,
      15.2% 20%,
      24.8% 23.4%,
      34.4% 32.4%,
      35.4% 44%
    );
  }
}

@media (min-width: 1280px) {
  .ourservice_grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
